
export const TOKEN_DATA = 'TOKEN_DATA';

const initialState = {
    data: false,
    type: 0
}

const tknDataReducer = (state = initialState, action) => {
    switch(action.type){
        case TOKEN_DATA:
            return {
                ...state,
                data: action.value ? action.value : action.data ,
                type: action.value.type ? action.value.type:0
            };

        default:
            return state;
    }
}

export default tknDataReducer;
