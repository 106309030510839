// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import user from './user'
import profileReducer from './completeProfile'
import checkProfileStatusReducer from './checkProfileStatus'
import affiliateStatusReducer from './isAffiliate'
import tknDataReducer from './tknData'
import qrReducer from './qrReducer'

const rootReducer = combineReducers({
    navbar,
    user,
    layout,
    checkProfileStatusReducer,
    affiliateStatusReducer,
    profileReducer,
    tknDataReducer,
    qrReducer
})

export default rootReducer
