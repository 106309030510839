export const CHECK_PROFILE_STATUS = 'CHECK_PROFILE_STATUS';

const initialState = {
    isCompleteProfile: false,
    isCompleteCard: false,
    type: ''
}

const checkProfileStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_PROFILE_STATUS:
            return {
                ...state,
                isCompleteProfile: action.value.profile,
                isCompleteCard: action.value.card_exists,
                type: action.value.type ? action.value.type : ''
            };

        default:
            return state;
    }
}

export default checkProfileStatusReducer
