// ** Logo
import logo from '@src/assets/images/logo/favicon.png'
import {connect} from "react-redux";
import React from "react";
import './loader.scss'

const Loader = (props) => {

    return (
        props.spinner && <div className='spinnerCustom  w-100 ' style={{height:document.body.scrollHeight+'px' }}>
            <div>
                <img className='fallback-logo' src={logo} alt='logo'/>
                <div className='loading'>
                    <div className='effect-1 effects'></div>
                    <div className='effect-2 effects'></div>
                    <div className='effect-3 effects'></div>
                </div>
            </div>
        </div>
    )
};


const mapStateToProps = state => ({
    spinner: state.user.isSpinner
});

export default connect(mapStateToProps)(Loader);
// export default SpinnerComponent
